import { useEffect, useState } from 'react';

/**
 * This hook can be used to check whether the javascript code has been loaded or not.
 * Ideal for below usecase
 *  - When redirecting - show a loader until page loads without showing the gatsby generated html
 * @returns {boolean} whether the page with javascript is loaded or not.
 */
const useIsPageLoaded = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  useEffect(() => {
    // During gatsby build time, window API is not available
    if (typeof window === 'undefined') {
      setIsPageLoaded(false);
    } else {
      setIsPageLoaded(true);
    }
  }, []);

  return isPageLoaded;
};

export default useIsPageLoaded;
