import React from 'react';
import { Router } from '@reach/router';

import Page from '~layout/Page';

import MagicLinkGet from '~containers/magic-link/MagicLinkGet';
import MagicLinkUse from '~containers/magic-link/MagicLinkUse';

const MagicLink = () => (
  <Page>
    <Router>
      <MagicLinkGet path="/magic-link" default />
      <MagicLinkUse path="/magic-link/:username/:token" />
    </Router>
  </Page>
);

export default MagicLink;
