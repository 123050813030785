import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { useForm } from 'react-hook-form';

import Link from '~components/Link';
import MessageBox from '~components/forms/MessageBox';
import { EmailInput } from '~components/form-inputs';
import { ButtonSubmit } from '~components/form-buttons';

const MagicLinkForm = ({ prefilledUsername, handleFormSubmit, state }) => {
  const {
    register: registerInput,
    handleSubmit,
    getValues
  } = useForm({
    defaultValues: { username: prefilledUsername }
  });
  const { isLoading, isError, data, error } = state;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {isError && <MessageBox text={error} isError />}

      {data && <MessageBox text={data && data.message} />}

      <EmailInput
        name="username"
        inputRef={registerInput}
        defaultValue={prefilledUsername || null}
        disabled={isLoading}
      />

      <ButtonSubmit disabled={isLoading}>Email me the link</ButtonSubmit>

      <Typography align="center">
        <Link to="/checkout/register" state={{ prefilledUsername: getValues().username }}>
          Sign Up
        </Link>{' '}
        ·{' '}
        <Link to="/login" state={{ prefilledUsername: getValues().username }}>
          Remembered Password?
        </Link>{' '}
        · <Link to="mailto:info@alephbeta.org?Subject=Need Help?">Need Help?</Link>
      </Typography>
    </form>
  );
};

MagicLinkForm.propTypes = {
  prefilledUsername: PropTypes.string,
  handleFormSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    data: PropTypes.shape({ message: PropTypes.string }),
    error: PropTypes.string
  }).isRequired
};

MagicLinkForm.defaultProps = {
  prefilledUsername: null
};

export default MagicLinkForm;
