/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useGetMagicLink } from '~hooks/useAuth';

import SEO from '~src/layout/SearchEngineOptimization';

import FlowLayout from '~components/FlowLayout';
import MagicLinkForm from '~components/forms/MagicLinkForm';
import LoadingSpinner from '~components/LoadingSpinner';
import useIsPageLoaded from '~hooks/useIsPageLoaded';
import useConversionFramework from '~hooks/useConversionFramework';
import RedirectWithNotice from '~layout/RedirectWithNotice';

const seo = {
  seo_title: 'Send A Magic Link Via Email',
  seo_description: 'Click on the link and you will be directed to your Aleph Beta account.',
  seo_keywords: 'aleph beta email magic link'
};

const MagicLinkGet = ({ location }) => {
  const { state } = location;
  const prefilledUsername = state && state.prefilledUsername;

  const [magicLinkState, getMagicLink] = useGetMagicLink();
  const isPageLoaded = useIsPageLoaded();
  const { isMarketingSite } = useConversionFramework();
  const [redirection, setRedirection] = useState(null);

  useEffect(() => {
    if (isMarketingSite) {
      setRedirection({
        url: `${process.env.GATSBY_MEMBERS_SITE_URL}/magic-link`
      });
    }
  }, [isMarketingSite]);

  if (isMarketingSite && redirection?.url) {
    return <RedirectWithNotice url={redirection.url} seconds={3} />;
  }

  if (!isPageLoaded || isMarketingSite || isMarketingSite === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SEO {...seo} />

      <FlowLayout
        title="Forgot Password"
        subtitle="Please enter your email address. We'll email you a link login to reset your password. Remembered your password?"
        to="/login"
        linkTitle="Go back to login."
      >
        <MagicLinkForm
          prefilledUsername={prefilledUsername}
          handleFormSubmit={getMagicLink}
          state={magicLinkState}
        />

        {/* Summary/Offer/Card/Image can go below */}
      </FlowLayout>
    </>
  );
};

export default MagicLinkGet;
