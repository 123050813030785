import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const getAccessLevelNameAndColorFromId = ({ access_level, is_admin }) => {
  if (is_admin) {
    return {
      plan_name: 'ADMIN',
      plan_color: '#f50057'
    };
  }

  switch (access_level) {
    case 1:
    case '1':
      return {
        plan_name: 'MEMBER',
        plan_color: '#76C79D'
      };
    case 2:
    case '2':
      return {
        plan_name: 'PREMIUM',
        plan_color: '#438DCE'
      };
    case 3:
    case '3':
      return {
        plan_name: 'EDUCATOR',
        plan_color: '#F0BE6D'
      };
    case 4:
    case '4':
      return {
        plan_name: 'PRODUCER',
        plan_color: '#2196F3'
      };
    case 5:
    case '5':
      return {
        plan_name: 'DIRECTOR',
        plan_color: '#009688'
      };
    default:
      return {
        plan_name: 'LIMITED',
        plan_color: '#808AA8'
      };
  }
};

/**
 * Please do note that this component did, but should not anymore, check the actual
 * `subscription_plan_id` field.
 * Starting from 2023-02-02 we differentiate people by the `access_level` not the plan ID.
 * Rules are the same, but the plan IDs can be wildly different now.
 * So, this whole component must be renamed, not just the props element it receives.
 *
 * @param access_level
 * @param is_admin
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const AccessLevelLabel = ({ access_level, is_admin, ...rest }) => {
  const { plan_name, plan_color } = getAccessLevelNameAndColorFromId({
    access_level,
    is_admin
  });

  return (
    <Box bgcolor={plan_color} px={1} color="#ffffff" borderRadius={2} textAlign="center" {...rest}>
      <Typography variant="caption" color="inherit">
        {plan_name}
      </Typography>
    </Box>
  );
};

AccessLevelLabel.propTypes = {
  access_level: PropTypes.number,
  is_admin: PropTypes.bool
};
AccessLevelLabel.defaultProps = {
  access_level: null,
  is_admin: false
};

export default AccessLevelLabel;
