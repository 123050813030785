/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useLoginWithMagicLink } from '~hooks/useAuth';

import SEO from '~src/layout/SearchEngineOptimization';

import Container from '~components/Container';
import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';
import FlowLayout from '~components/FlowLayout';
import MagicLinkPaper from '~components/magic-link-use/MagicLinkPaper';

import BackgroundImg from '~images/other/flow-background.svg';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import useCheckout from '~hooks/useCheckout';
import useIsPageLoaded from '~hooks/useIsPageLoaded';
import useConversionFramework from '~hooks/useConversionFramework';
import RedirectWithNotice from '~layout/RedirectWithNotice';

const seo = {
  seo_title: 'Login With Magic Link',
  seo_description: 'One-click easy access to your Aleph Beta account.',
  seo_keywords: 'aleph beta magic link'
};

const MagicLinkUseContents = ({ username, token }) => {
  const [{ isLoading, isError, data, error }] = useLoginWithMagicLink(username, token);
  const [checkoutState] = useCheckout();
  const hasProduct = !!(checkoutState && checkoutState.product);

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {isError && (
        <FlowLayout title="Something went wrong with Your Magic Link!">
          <>
            <MessageBox text={error} isError />

            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
              <Grid item xs={12} md={6}>
                <Button
                  component={GatsbyLink}
                  to="/login"
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Log in
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  component={GatsbyLink}
                  to="/checkout/register"
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </>
        </FlowLayout>
      )}

      {data && (
        <MagicLinkPaper
          username={data.user.username}
          subscription_plan_id={data.user.access_level}
          is_in_checkout_process={hasProduct}
        />
      )}
    </>
  );
};

const MagicLinkUse = ({ username, token }) => {
  const isPageLoaded = useIsPageLoaded();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isUserStateLoading } = session;
  const [redirection, setRedirection] = useState(null);

  useEffect(() => {
    if (isMarketingSite) {
      setRedirection({
        url: `${process.env.GATSBY_MEMBERS_SITE_URL}/magic-link/${username}/${token}`,
        description: 'We are processing your request.'
      });
    }
  }, [username, token, isMarketingSite]);

  if (isMarketingSite && redirection?.url) {
    return (
      <RedirectWithNotice url={redirection.url} description={redirection.description} seconds={3} />
    );
  }

  if (!isPageLoaded || isMarketingSite || isMarketingSite === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SEO {...seo} />

      <Container center size={100} background={`url(${BackgroundImg})`}>
        {!isUserStateLoading ? (
          <MagicLinkUseContents username={username} token={token} />
        ) : (
          <LoadingSpinner />
        )}
      </Container>
    </>
  );
};

export default MagicLinkUse;
